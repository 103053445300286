import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"
import { Container, Row, Card, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function About() {
    return (
        <Layout>
            <SEO title="About" />
            <Container>
                <br></br>
                <div class="p-5 mb-4 bg-light rounded-3">
                    <div class="container-fluid py-5">
                        <h1 class="display-5 fw-bold">Hello once again, Nice to meet you</h1>
                        <p class="col-md-8 fs-4">Using a series of utilities, you can create this jumbotron, just like the one in previous versions of Bootstrap. Check out the examples below for how you can remix and restyle it to your liking.</p>
                    </div>
                </div>

                <hr></hr>

                <Row Row xs={1} md={2} lg={3} className="g-4">
                    <Col>
                        <Card className="text-center">
                            <Card.Img variant="top" src="https://rbcreate.com/static/ProfilePic-5ac79ebf06dd81a7c9cad12e3a4add5a.png" width="100px" />
                            <Card.Body>
                                <Card.Title>Name Here</Card.Title>
                                <Card.Text>
                                    Founder and CEO
                                </Card.Text>
                                <Card.Text>
                                    With supporting text below as a natural lead-in to additional content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="text-center">
                            <Card.Img variant="top" src="https://rbcreate.com/static/ProfilePic-5ac79ebf06dd81a7c9cad12e3a4add5a.png" width="100px" />
                            <Card.Body>
                                <Card.Title>Name Here</Card.Title>
                                <Card.Text>
                                    Founder and CEO
                                </Card.Text>
                                <Card.Text>
                                    With supporting text below as a natural lead-in to additional content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="text-center">
                            <Card.Img variant="top" src="https://rbcreate.com/static/ProfilePic-5ac79ebf06dd81a7c9cad12e3a4add5a.png" width="100px" />
                            <Card.Body>
                                <Card.Title>Name Here</Card.Title>
                                <Card.Text>
                                    Founder and CEO
                                </Card.Text>
                                <Card.Text>
                                    With supporting text below as a natural lead-in to additional content.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>


            </Container>
        </Layout>
    )
}